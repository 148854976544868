.Content {
  display: flex;
  align-items: center;
  flex-flow: column;
}

.aboutMe {
  display: flex;
  justify-content: space-around;
  font-family: 'Sriracha', arial, sans-serif;
  color: white;
  margin: 0 15em 5em 15em;
}

.profilePic {
  height: 25em;
  width: 25em;
  background: url('../../media/images/me_empty_background.png');
  background-size: 20em 25em;
  background-repeat: no-repeat;
  background-position: bottom;
  position: absolute;
  bottom: 0;
  z-index: -1;
  /* box-shadow: 5px 5px 10px rgba(0,0,0,0.5); */
  /* margin: .5em -3em -3em .5em; */
}
