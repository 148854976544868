.Content {
  display: flex;
  justify-content: space-around;
}

.Cards {
  width: 35em;
  background: #fffffb;
  box-shadow: 5px 5px 10px rgba(0,0,0,0.5);
  background-color: #c2b6b6;
  background-image: linear-gradient(315deg, #c2b6b6 0%, #576574 74%);
  margin: 0 5em 0 5em;
}

.headerContent {
  display: flex;
  justify-content: space-around;
}

.javaTitle {
  font-family: 'Sriracha', arial, sans-serif;
  text-shadow: 2px 4px 2px rgba(0,0,0,0.7);
  color: rgb(244,155,44);
  margin-bottom: .5em;
}

.goTitle {
  font-family: 'Sriracha', arial, sans-serif;
  text-shadow: 2px 4px 2px rgba(0,0,0,0.7);
  color: rgb(93,188,210);
  margin-bottom: .5em;
}

.cardContent {
  display: flex;
  justify-content: flex-start;
}

.goImage {
  height: 100px;
  width: 100px;
  background: url('../../media/images/Go-Logo_White.svg');
  background-color: black;
  background-size: 100px 100px;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  box-shadow: 5px 5px 10px rgba(0,0,0,0.5);
  margin: .5em -3em -3em .5em;
}

.javaImage {
  height: 100px;
  width: 100px;
  background: url('../../media/images/java-logo.png');
  background-color: black;
  background-size: 50px 90px;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  box-shadow: 5px 5px 10px rgba(0,0,0,0.5);
  margin: .5em -3em -3em .5em;
}

.LinkButton {
  color: black;
  font-family: 'Sriracha', arial, sans-serif;
  text-shadow: 2px 4px 2px rgba(0,0,0,0.7);
  transition: 0.3s;
  margin: 1em 1em 1em 1em;
}

.LinkButton:hover {
  cursor: pointer;
  color: black;
  filter:invert(100%);
}
